import type { AlertProps } from '@canalplus/dive';
import { AlertStatus, Alert as DiveAlert } from '@canalplus/dive';
import type { JSX } from 'react';
import { useMemo } from 'react';
import { useTranslation } from '../../lang';

/** Alert component based on dive's Alert with i18n ARIA labels */
export default function Alert(props: AlertProps): JSX.Element | null {
  const { t } = useTranslation();
  const iconsAriaLabels = useMemo(
    () => ({
      [AlertStatus.Error]: t('Alert.error'),
      [AlertStatus.Hint]: t('Alert.information'),
      [AlertStatus.Info]: t('Alert.information'),
      [AlertStatus.Success]: t('Alert.success'),
      [AlertStatus.Warning]: t('Alert.attention'),
      close: t('Icon.close'),
    }),
    [t]
  );
  return <DiveAlert iconsAriaLabels={iconsAriaLabels} {...props} />;
}
