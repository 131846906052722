import { addQueryParam } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import type { LocationDescriptor } from 'history';
import { useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import { StrateMode } from '../../../constants/strates';
import { TemplateTypes } from '../../../constants/templateTypes';
import { Queries } from '../../../constants/url';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { useAppHistory } from '../../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useAuthFromExternalSiteWithIDPToken } from '../../../helpers/hooks/useAuthFromExternalSiteWithIDPToken';
import { useSigninRedirect } from '../../../helpers/pass/useSigninRedirect';
import {
  clientSideOneShopRedirection,
  clientSideSelectRegionRedirection,
} from '../../../helpers/user/user-helper';
import {
  offerLocationSelector,
  platformSelector,
} from '../../../store/slices/application-selectors';
import { immersiveSelector } from '../../../store/slices/immersive-selectors';
import { pageSelector } from '../../../store/slices/page-selectors';
import {
  idpTokenSelector,
  isKidsProfileSelector,
} from '../../../store/slices/user-selectors';
import type { IState } from '../../../store/types/State-type';
import type { LocationState } from '../../../typings/routing';
import { getLocationState } from '../helpers/getLocationState';
import type { LinkerProps, LinkerSettings } from '../types';
import {
  getOnClickGenerated,
  launchPlayerLinker,
  redirectRouter,
} from './helpers/useLinkerHelper';

export type UseLinker = {
  /**
   * Returns settings for the Linker component. Useful when trying to get `onClick`, `href` and `target` values for your action element
   * @param props LinkerProps
   * @returns LinkerSettings The linker settings
   * @example
      const linkerSettings = getLinkerSettings({ data: { onClick: button.onClick }});
      if(linkerSettings.href) {
        return <a className={styles.defaultTemplate_button} onClick={linkerSettings.onClick} href={linkerSettings.href} target={linkerSettings.target}>
          My Link
        </a>
      }else {
        return <button type="button" className={styles.defaultTemplate_button} onClick={linkerSettings.onClick}>
          My Link
        </button>
      }
   */
  getLinkerSettings: (props: LinkerProps) => LinkerSettings;
};

/**
 * Give a method (getLinkerSettings) to generate LinkerSettings.
 * Useful when trying to get `onClick`, `href` and `target` values for your action element
 * @example
    const { getLinkerSettings } = useLinker();
 * @returns UseLinker object { getLinkerSettings }
 */
const useLinker = (): UseLinker => {
  const store = useStore<IState>();
  const history = useAppHistory();
  const dispatch = useAppDispatch();

  const idpToken = useSelector(idpTokenSelector);
  const { authenticate } = useAuthFromExternalSiteWithIDPToken(idpToken);
  const handleConnectClick = useSigninRedirect();

  const getLinkerSettings = useMemo(() => {
    return (linkerProps: LinkerProps): LinkerSettings => {
      const {
        data,
        objKey = 'onClick',
        target = '_blank',
        replace,
      } = linkerProps;
      const { mainOnClick, subOnClick, context, contentID } = data || {};
      const {
        displayTemplate,
        path,
        URLWebsite,
        displayMode,
        target: onClickTarget,
      } = subOnClick || mainOnClick || {};

      if (displayTemplate === Template.ExternalSiteWithIDPToken && URLWebsite) {
        return {
          onClick: () => {
            authenticate(URLWebsite);
          },
        };
      }

      if (displayTemplate === Template.Authentication) {
        return { onClick: () => handleConnectClick() };
      }

      if (displayTemplate === Template.ChangeGeoZone) {
        const onSelectRegionClick = (contentId?: string) =>
          clientSideSelectRegionRedirection(contentId);

        return {
          onClick: () => {
            onSelectRegionClick(contentID);
          },
        };
      }

      if (displayTemplate === TemplateTypes.LAUNCH_ONE_SHOP) {
        const onOneShopClick = (contentId?: string) =>
          clientSideOneShopRedirection(contentId);

        return {
          onClick: () => {
            onOneShopClick(contentID);
          },
        };
      }

      // Values from redux store
      const reduxState = store.getState();
      const immersiveState = immersiveSelector(reduxState);
      const offerLocation = offerLocationSelector(reduxState);
      const pageState = pageSelector(reduxState);
      const platform = platformSelector(reduxState);
      const isKids = isKidsProfileSelector(reduxState);

      const publicConfig = getPublicConfig();
      const linkTarget = $_BUILD_RENDERMODE_CSR
        ? '_self'
        : onClickTarget || target;
      const query = path?.split('?')[1] || '';
      const brand = publicConfig.api.hodor.defaultAppKey;

      const handleClick = getOnClickGenerated(
        linkerProps,
        dispatch,
        offerLocation,
        platform,
        history
      );

      if (displayTemplate === Template.ExternalSite && URLWebsite) {
        return { href: URLWebsite, target: linkTarget, onClick: handleClick };
      }

      if (displayTemplate === Template.UpdateRights) {
        const refreshRightRef = addQueryParam(
          window.location.href,
          Queries.RefreshRight,
          'true'
        );
        return { href: refreshRightRef, target: '_self', onClick: handleClick };
      }

      if (displayTemplate === Template.DownloadManager) {
        return { onClick: handleClick };
      }

      if (displayTemplate === Template.Player && objKey !== StrateMode.LiveTv) {
        return { onClick: handleClick };
      }

      const { location } = history;

      const state = getLocationState({
        context,
        immersiveState,
        location,
        pageState,
        replace,
        ...(mainOnClick ? { mainOnClick } : { subOnClick }),
      });

      const to: LocationDescriptor<LocationState> = {
        pathname: query ? path?.split('?')[0] : path,
        search: query,
        state: Object.keys(state).length ? state : undefined,
      };

      // In the case is a navigation with the router, we return all information needed for routing
      return {
        href: to.pathname,
        target: '_self',
        onClickWithoutRouting:
          displayMode === Template.Fullscreen || objKey === StrateMode.LiveTv
            ? (event) => {
                event.persist();
                launchPlayerLinker({
                  event,
                  data,
                  isKids,
                  brand,
                  objKey,
                  dispatch,
                });
                handleClick(event);
              }
            : handleClick,
        onClick:
          displayMode === Template.Fullscreen || objKey === StrateMode.LiveTv
            ? (event) => {
                event.preventDefault();
                event.persist();
                launchPlayerLinker({
                  event,
                  data,
                  isKids,
                  brand,
                  objKey,
                  dispatch,
                });
                handleClick(event);
              }
            : (event) => {
                event.preventDefault();
                handleClick(event);
                redirectRouter(history, to, replace);
              },
        to,
      };
    };
  }, [authenticate, dispatch, handleConnectClick, history, store]);

  return { getLinkerSettings };
};

export default useLinker;
