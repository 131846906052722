import {
  Avatar,
  HeaderDropdownSeparator,
  HeaderUserDropdown,
  HeaderUserDropdownProfiles,
  MediaImage,
  type AvatarProps,
} from '@canalplus/dive';
import { PROFILE } from '@canalplus/mycanal-commons';
import type { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import classNames from 'classnames/bind';
import type { ComponentProps, JSX } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { DeepPartial } from 'ts-essentials';
import { getHeaderUserMenuProfiles } from '../../../helpers/navigation/navigation-helper';
import { LAYER_PAGE } from '../../../helpers/oneNavigation/layers';
import { rewriteObjectUrls } from '../../../helpers/proxyUrls/proxyUrls';
import { useTranslation } from '../../../lang';
import {
  getFeatureToggleKidsProfiles,
  isClientRenderSourceSelector,
} from '../../../store/slices/application-selectors';
import {
  authenticatedSelector,
  hasProfilesLengthSelector,
  isKidsProfileSelector,
  profileImgSelector,
  profileNameSelector,
  userProfilesSelector,
} from '../../../store/slices/user-selectors';
import Linker from '../../Linker/Linker';
import { useProfileSelector } from '../../Profiles/ProfileSelectorMyCanal/hooks/useProfileSelector';
import HeaderProfileContent from './HeaderProfileContent';
import styles from './HeaderProfileOneDiscovery.module.css';

const cx = classNames.bind(styles);

export type HeaderProfileOneDiscoveryProps = {
  data?: ApiV2NavigationItem;
  isActive?: boolean;
};

function HeaderProfileOneDiscovery({
  data,
  isActive,
}: HeaderProfileOneDiscoveryProps): JSX.Element {
  const { onClick } = data || {};
  const { t } = useTranslation();
  const triggerAriaLabel = t('ProfileManager.profileIconAriaLabel');

  const isClient = useSelector(isClientRenderSourceSelector);
  const isKidsProfile = useSelector(isKidsProfileSelector);
  const isLoggedIn = useSelector(authenticatedSelector);
  const isProfileSelectorRendered = useSelector(hasProfilesLengthSelector);
  const triggerImageAlt = useSelector(profileNameSelector);
  const featKidsProfiles = useSelector(getFeatureToggleKidsProfiles);

  // To ensure that the URL of the userMenu avatar icon is properly proxied, we apply a guardrail as close as possible to the component
  const triggerImageUrl = rewriteObjectUrls(useSelector(profileImgSelector));
  const userProfiles = rewriteObjectUrls(useSelector(userProfilesSelector));
  const profiles = userProfiles?.filter((profile) => profile.type === PROFILE);
  const profilesWithoutKids = userProfiles?.filter(
    (profile) => profile.type === PROFILE && !profile.isKidsProfile
  );

  const profilesList = !featKidsProfiles ? profilesWithoutKids : profiles;
  const {
    handleProfileClick,
    handleEditButton,
    handleAddProfileClick,
    renderModals,
  } = useProfileSelector(LAYER_PAGE);
  const profileItems = useMemo(() => {
    if (!profilesList || !isProfileSelectorRendered) {
      return [];
    }
    return getHeaderUserMenuProfiles(profilesList, handleProfileClick);
  }, [profilesList, isProfileSelectorRendered, handleProfileClick]);

  const avatarProps: AvatarProps = {
    'aria-label': triggerAriaLabel,
    isButton: !$_BUILD_RENDERMODE_CSR,
    isKids: isClient && isKidsProfile, // Currently, in SSR when don't know if the profile is a kids profile
    variant: isLoggedIn && triggerImageUrl ? 'default' : 'account',
    ...(isLoggedIn &&
      triggerImageUrl && {
        image: <MediaImage src={triggerImageUrl} alt={triggerImageAlt} />,
      }),
  };

  const headerUserDropdownProps = {
    options: { strategy: 'fixed' },
    isSelected: isActive,
    closeIconAriaLabel: t('ProfileManager.closeModal'),
    menuStyles: cx('HeaderProfileOneDiscovery__menu'),
    backdropStyles: cx('HeaderProfileOneDiscovery__backdrop'),
  } satisfies DeepPartial<ComponentProps<typeof HeaderUserDropdown>>;

  if ($_BUILD_RENDERMODE_CSR) {
    return (
      <HeaderUserDropdown
        {...headerUserDropdownProps}
        renderTrigger={({ triggerProps }) => (
          <Linker
            data={{ mainOnClick: onClick }}
            aria-label={triggerAriaLabel}
            data-tv-focusmode="attribute"
          >
            <Avatar {...avatarProps} {...triggerProps} />
          </Linker>
        )}
      />
    );
  }

  return (
    <>
      <HeaderUserDropdown
        {...headerUserDropdownProps}
        renderTrigger={({ triggerProps }) => (
          <Avatar {...avatarProps} {...triggerProps} />
        )}
      >
        {isProfileSelectorRendered && (
          <>
            <HeaderUserDropdownProfiles
              items={profileItems}
              labelEdit={t('ProfileManager.manage')}
              onClickEdit={handleEditButton}
              labelAddProfile={t('ProfileManager.add')}
              onClickAddProfile={handleAddProfileClick}
            />
            <HeaderDropdownSeparator />
          </>
        )}
        <HeaderProfileContent />
      </HeaderUserDropdown>
      {isProfileSelectorRendered && renderModals()}
    </>
  );
}

export default HeaderProfileOneDiscovery;
