import classNames from 'classnames';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from '../../lang';
import styles from './Spinner.css';

export type SpinnerProps = {
  withKeyboard?: boolean;
  color?: string;
  size?: number;
  top?: number;
  className?: string;
  delay?: number;
  isUnsetPosition?: boolean;
};

/**
 * This component is in charge of displaying Spinner template
 *
 * @param withKeyboard whether if there are a keyboard
 * @param color        custom color of the spinner
 * @param size         custom size of the spinner
 * @param top          custom top position of the spinner
 * @param className    to override style
 * @param delay        milliseconds
 */
function Spinner({
  withKeyboard = false,
  color = '',
  size = 9,
  top = 9,
  className = '',
  delay = 0,
  isUnsetPosition = false,
}: SpinnerProps): JSX.Element {
  const { t } = useTranslation();
  const [isSpinnerHidden, setIsSpinnerHidden] = useState<boolean>(!!delay);

  const styleColor = color
    ? { borderColor: `${color} transparent ${color} ${color}` }
    : {};
  const styleSize = size
    ? {
        width: `${size}rem`,
        height: `${size}rem`,
        left: `calc(50% - ${size}rem / 2)`,
      }
    : {};
  const styletop = top ? { top: `calc(50% - ${top}rem / 2)` } : {};

  useEffect(() => {
    const delayTimeoutId = setTimeout(() => {
      setIsSpinnerHidden(false);
    }, delay);
    return () => clearTimeout(delayTimeoutId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classNames(
        styles.spinner,
        {
          [styles.spinner__withKeyboard!]: withKeyboard,
          [styles['spinner--hidden']!]: isSpinnerHidden,
          [styles.spinner__unsetPosition!]: isUnsetPosition,
        },
        className
      )}
      style={{ ...styleSize, ...styleColor, ...styletop }}
      title={t('Icon.loading')}
    />
  );
}

export default Spinner;
