import type { ThumborUrl } from '@canalplus/dive';
import { getThumborUrl } from '@canalplus/dive';
import { THUMBOR } from '@canalplus/dive-utils';
import { Template } from '@canalplus/sdk-hodor';
import Metas from '../../../config/application/offerLocation/default';
import type { AppKey } from '../../../config/application/types';
import type {
  I18nOneDiscoveryFunction,
  OneDiscoveryTranslationsStringPaths,
} from '../../lang';
import type { Meta } from './types';

export type BuildMetaParameter = {
  displayName?: string | null;
  displayTemplate?: string | null;
  routerPathname?: string;
  appKey?: AppKey;
  metaParams: Meta;
  urlImage?: string;
};

export const buildMeta = ({
  displayName,
  displayTemplate,
  appKey,
  metaParams,
  urlImage,
}: BuildMetaParameter): Meta => {
  const isDetailTemplate =
    displayTemplate === Template.DetailShow ||
    displayTemplate === Template.DetailPage ||
    displayTemplate === Template.DetailSeason;
  const isContentGrid = displayTemplate === Template.ContentGrid;

  // [CSR && SSR - case contentGrid]
  if (isContentGrid) {
    return { title: displayName || undefined, ...metaParams, urlImage };
  }

  // [CSR && SSR - case DetailPage, DetailSeason and DetailShow]
  /* /!\ see how work `metaTagsTranslator` function */
  if (isDetailTemplate) {
    const isTVod = appKey === 'canalvod';

    return {
      ...metaParams,
      title: `${metaParams?.title || displayName}${isTVod ? ` t(MetaTags.tvodTitle)` : ` t(MetaTags.title)`}`,
      urlImage,
    };
  }

  return { ...metaParams, urlImage };
};

/*
  `metaTagsTranslator` function must be called from a component,
   to be able to use the I18n => 't()' function
   - param => t: (title: string) => string
   - param => title: string => [Example of title: "The walking dead t(MetaTags.title)"]
*/
export const metaTagsTranslator = (
  t: I18nOneDiscoveryFunction,
  title: string
): string => {
  const metaTagsRegex = /t\((MetaTags\..*)\)/;

  // get first capturing group
  // cast metaTagsExtracted because we don't have any type guards
  const metaTagsExtracted = metaTagsRegex.exec(title)?.[1] as
    | OneDiscoveryTranslationsStringPaths
    | undefined;

  return metaTagsExtracted
    ? title.replace(metaTagsRegex, t(metaTagsExtracted))
    : title;
};

/** removes translated MetaTags.title in a string */
export const buildSharedTitle = (
  translatedTitle: string,
  t: I18nOneDiscoveryFunction
): string => {
  return !RegExp(t('MetaTags.title')).exec(translatedTitle)
    ? translatedTitle
    : translatedTitle.replace(`${t('MetaTags.title')} `, '');
};

const META_TAG_DEFAULT = { width: 1200, height: 630 } as const;

export const getThumborImageMetaTag = (imageUrl: string): ThumborUrl => {
  const metaTagWidth = Metas?.app?.head?.meta?.find(
    (el) => el.property === 'og:image:width'
  )?.content;

  const metaTagHeight = Metas?.app?.head?.meta?.find(
    (el) => el.property === 'og:image:height'
  )?.content;

  return getThumborUrl({
    url: imageUrl,
    includesAllowedSourceInUrl:
      THUMBOR.URLS_ALREADY_INCLUDING_ALLOWED_SOURCE.some((endpoint) =>
        imageUrl?.includes(endpoint)
      ),
    dimensions: {
      height: Number(metaTagHeight) || META_TAG_DEFAULT.height,
      width: Number(metaTagWidth) || META_TAG_DEFAULT.width,
    },
    quality: THUMBOR.QUALITY_DEFAULT,
  });
};
