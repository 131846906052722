import { removeQueriesFromUrl } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { performWsFromPath } from '../../../../../api/Hodor/performWsFromPath';
import { Queries } from '../../../../../constants/url';
import { computeApiV2OnClick } from '../../../../../helpers/hodor/computeApiV2OnClick';
import { useAppDispatch } from '../../../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../../../helpers/hooks/useInvariantSelector';
import { getId } from '../../../../../helpers/utils/utils-helper';
import {
  hodorSdkConfigSelector,
  tokenCMSSelector,
} from '../../../../../store/slices/application-selectors';
import { openImmersive } from '../../../../../store/slices/immersive';
import { immersiveSelector } from '../../../../../store/slices/immersive-selectors';
import { requestTemplate } from '../../../../../store/slices/page';
import { pageSelector } from '../../../../../store/slices/page-selectors';
import { subscriberIdSelector } from '../../../../../store/slices/user-selectors';
import type { IState } from '../../../../../store/types/State-type';
import type {
  AppHistory,
  AppLocation,
  LocationState,
} from '../../../../../typings/routing';

/**
 * Each Hodor URLPage contains a tokenCMS specific to the user's rights.
 * Following a connection/disconnection, the rights change and therefore the tokenCMS changes.
 * The URLPage contained in the previous onClicks therefore becomes invalid.
 * Exception: LiveTv rubrics do not contain URLPage
 */
export function isValidOnClick(
  onClick: Routing.IOneDiscoveryOnClick,
  tokenCMS: string
): boolean {
  return !!onClick && !!tokenCMS && !!onClick?.URLPage?.includes(tokenCMS);
}

/** The 3 main properties that define a page are displayTemplate, pathname and URLPage */
export function isOnClickDifferFromState(
  onClick: Routing.IOneDiscoveryOnClick,
  state?: Routing.IOneDiscoveryOnClick
): boolean {
  return (
    onClick.displayTemplate !== state?.displayTemplate ||
    onClick.path !== state?.path ||
    onClick.URLPage !== state?.URLPage
  );
}

/**
 * Navigation within the app relies entirely on information received from the Hodor API.
 * The data required for navigation is in Hodor "onClick" objects.
 * Each clickable element transmitted by Hodor systematically contains an "onClick" object.
 * Every time the user clicks on a clickable element, the "onClick" object is injected into the link's "history.state" (https://developer.mozilla.org/en-US/docs/Web/API/History/state)
 * and it's here in the "useGetPageContent" hook where we retrieve and consume it.
 * When the user does a browser navigation (back or forward), the "onClick" previously injected into the history.state is used in the same way.
 *
 * This hook updates the "page" or "immersive" data in the store and it is then the Page component that listens and consumes this new data.
 */
export default function useGetPageContent(
  history: AppHistory,
  location: AppLocation
): void {
  const dispatch = useAppDispatch();
  const state = useStore<IState>().getState();

  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);
  const subscriberId = useSelector(subscriberIdSelector);
  const tokenCMS = useInvariantSelector(tokenCMSSelector);
  const page = useSelector(pageSelector);
  const immersive = useSelector(immersiveSelector);

  useEffect(() => {
    const {
      pathname: locationPathname,
      search,
      state: {
        fromPlainTextHtml,
        immersive: {
          mainOnClick: immersiveMainOnClick = undefined,
          context: immersiveContext = undefined,
        } = {},
        page: {
          mainOnClick: pageMainOnClick = undefined,
          subOnClick: pageSubOnClick = undefined,
          context: pageContext = undefined,
        } = {},
        fromSSR,
      } = {},
    } = location;

    const mainOnClick = immersiveMainOnClick || pageMainOnClick;
    const mainContext = immersiveContext || pageContext;

    let pathWithQueryParams = [locationPathname, search]
      .filter(Boolean)
      .join('');

    if (hodorSdkConfig) {
      // When the "refreshRight" query parameter is present in the URL we need to clear it on client-side.
      // HOWEVER we can't do it too early since passAuthentication() method needs it to check if it should
      // call Pass.createToken with noCache=true. We rely on hodorSdkConfig to be defined since hodorSdkConfig
      // itself relies on pass token to be defined.
      //
      // I'm sorry for that, this was done a a recfix for one another mergency topic. FIXME asap.
      //
      pathWithQueryParams = removeQueriesFromUrl(pathWithQueryParams, [
        Queries.RefreshRight,
      ]);
    }

    const isBrowserNavigation = history.action === 'POP';
    const uniqueId = getId();

    if (fromSSR) {
      // The app manually recreates and adds the onClickPage in the history (from store data) for the first page generated (SSR)
      // in order to reproduce the logic of the Linker component which systematically adds the onClickPage/onCLickImmersive.
      // This way, when the user navigates back to this page, the useGetPageContent will use this object.
      history.replace(pathWithQueryParams, {
        fromSSR: false,
        ...(page && {
          page: {
            mainOnClick: computeApiV2OnClick({ store: page }),
          },
        }),
      });

      return;
    }

    if (!mainOnClick || !isValidOnClick(mainOnClick, tokenCMS)) {
      // When the onClick is invalid, the app calls wsFromPath to retrieve a fresh onClick (redirectTo).
      const pathForWsFromPath = `${locationPathname}${search}`;

      // When a user clicks on a link, its content must be opened in an immersive if the "immersive" context is present
      // or if the link comes from the "PlainTextHtmlTemplate.tsx" template (cf https://gitlab.canalplus.pro/deddev/dedmycanalwebapp/-/issues/5806).
      const shouldOpenImmersive =
        !isBrowserNavigation &&
        (mainContext === 'immersive' || !!fromPlainTextHtml);

      (async () => {
        try {
          const wsFromPath = await performWsFromPath({
            dispatch,
            hodorSdkConfig,
            isClient: true,
            path: pathForWsFromPath,
            shouldOpenImmersive,
            state,
            subscriberId,
            ...(search && { search }),
          });

          if (wsFromPath && 'redirectTo' in wsFromPath) {
            const onClick = computeApiV2OnClick({
              redirectTo: wsFromPath.redirectTo,
            });

            const locationState: LocationState = shouldOpenImmersive
              ? {
                  immersive: {
                    mainOnClick: onClick,
                  },
                  page: {
                    mainOnClick: pageMainOnClick,
                  },
                }
              : {
                  page: {
                    mainOnClick: onClick,
                  },
                };

            return history.replace(pathWithQueryParams, locationState);
          }

          if (wsFromPath && 'redirect' in wsFromPath) {
            return history.replace(wsFromPath.redirect);
          }
        } catch (error) {
          // If the Hodor.wsFromPath throws an error, we display a generic error page
          dispatch(
            requestTemplate({
              mainOnClick: {
                displayTemplate: Template.Error,
                displayName: '',
                path: pathForWsFromPath,
              },
            })
          );
        }
      })();

      return;
    }

    const shouldUpdateImmersiveTemplate =
      !!immersiveMainOnClick &&
      isOnClickDifferFromState(immersiveMainOnClick, immersive.mainOnClick);

    if (shouldUpdateImmersiveTemplate) {
      const {
        adult,
        displayTemplate,
        displayName,
        path,
        trackingContext,
        URLPage,
        parameters,
      } = immersiveMainOnClick;

      dispatch(
        openImmersive({
          mainOnClick: {
            adult,
            displayTemplate,
            displayName,
            path,
            URLPage,
            parameters,
            trackingContext,
          },
        })
      );
    }

    // When we switch between DetailV5 tabs, we perform a router replace. In this case too, we should not dispatch a requestTemplate action
    const shouldUpdatePageTemplate =
      ((!!pageMainOnClick &&
        isOnClickDifferFromState(pageMainOnClick, page?.mainOnClick)) ||
        (!!pageSubOnClick &&
          !!page?.subOnClick &&
          isOnClickDifferFromState(pageSubOnClick, page?.subOnClick))) &&
      history.action !== 'REPLACE';

    if (shouldUpdatePageTemplate) {
      dispatch(
        requestTemplate({
          mainOnClick: pageMainOnClick,
          subOnClick: pageSubOnClick,
          uniqueId,
        })
      );
    }

    // On CSR rendering, hodorSdkConfig may not be hydrated until later in the boot sequence.
    // We use a double negate (!!) to avoid running this hook each time the config is updated.
  }, [location.pathname, !!hodorSdkConfig]); // eslint-disable-line react-hooks/exhaustive-deps
}
